import { io } from 'socket.io-client';

// Use environment variables to determine the backend URL
const backendURL =
  process.env.NODE_ENV === 'production'
    ? 'wss://krafsync.onrender.com' // Your production WebSocket URL
    : 'http://localhost:5000'; // Development WebSocket URL

const socket = io(backendURL, {
  transports: ['websocket', 'polling'], // Ensure proper transport fallback
});

// Export the socket instance for use across the app
export default socket;
