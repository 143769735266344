import React, { Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { getCompany } from '../../actions/company';
import Spinner from '../layout/Spinner';

import CompanyDetails from './CompanyDetails';
import GeneralSettings from './GeneralSettings';
import CompanyProfile from './CompanyProfile';
import CompanyAttendance from './CompanyAttendance';
import HrSetting from './HrSetting';

import { FaRegBuilding } from "react-icons/fa";
import { TbLogicAnd } from "react-icons/tb";
import { RiProfileLine } from "react-icons/ri";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { FaPeopleGroup } from "react-icons/fa6";

const EditCompany = ({getCompany, company: {company, loading}}) => {
    const [activeOption, setActiveOption] = useState('option1');

    useEffect(() => {
        getCompany();
    }, [getCompany]);

    if (!company) {
        return <Spinner />;
    }

    if (loading) {
        return <Spinner />;
    }

    const getButtonStyle = (option) => ({
        backgroundColor: activeOption === option ? 'rgb(52, 89, 154)' : 'transparent',
        color: activeOption === option ? 'white' : 'black',
    });

    return (
        <Fragment>
        <main className="ep-main roboto-regular">
            <div className="ep-container">
                <div className="ep-left-wrapper">
                    <div className="ep-left-item">
                        <div className="ep-li-title">{company.companyName}</div>
                        <div className="ep-li-desc">Manage company details, profile and general settings</div>

                        <div onClick={() => setActiveOption('option1')} style={getButtonStyle('option1')} className="ep-li-link">
                            <span><FaRegBuilding /></span>
                            <span>Company Details</span>
                        </div>

                        <div onClick={() => setActiveOption('option2')} style={getButtonStyle('option2')} className="ep-li-link">
                            <span><RiProfileLine /></span>
                            <span>Company Profile</span>
                        </div>

                        <div onClick={() => setActiveOption('option5')} style={getButtonStyle('option5')} className="ep-li-link">
                            <span><FaPeopleGroup /></span>
                            <span>Human Resource</span>
                        </div>

                        <div onClick={() => setActiveOption('option3')} style={getButtonStyle('option3')} className="ep-li-link">
                            <span><TbLogicAnd /></span>
                            <span>General Settings</span>
                        </div>

                        <div onClick={() => setActiveOption('option4')} style={getButtonStyle('option4')} className="ep-li-link">
                            <span><HiOutlineClipboardDocumentCheck /></span>
                            <span>Attendance</span>
                        </div>

                    </div>  
                </div>

                <div className="ep-right-wrapper">
                    {activeOption === 'option1' && <CompanyDetails company={company} />}
                    {activeOption === 'option2' && <CompanyProfile company={company} />}
                    {activeOption === 'option3' && <GeneralSettings company={company} />}
                    {activeOption === 'option4' && <CompanyAttendance company={company} />}
                    {activeOption === 'option5' && <HrSetting company={company} />}
                </div>
            </div>
        </main>
    </Fragment> 
    );
};

const mapStateToProps = (state) => ({
    company: state.company
});


export default connect(mapStateToProps, {getCompany})(EditCompany);
