import React, { useState, useEffect } from 'react';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import { ieNoOpen } from 'helmet';

const HrSetting = ({ company }) => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        if (company) {
            setUsers(company.users || []); // Populate users from company data
            setSelectedUsers(company.usersLeaveRule || []); // Initialize selected users
        }
    }, [company]);

    const handleCheckboxChange = (userId) => {
        setSelectedUsers((prevSelectedUsers) =>
            prevSelectedUsers.includes(userId)
                ? prevSelectedUsers.filter((id) => id !== userId) // Remove if already selected
                : [...prevSelectedUsers, userId] // Add if not already selected
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if (token) setAuthToken(token);

        try {
            const res = await axios.put(
                `/api/company/${company._id}/update-leave-rule`,
                { usersLeaveRule: selectedUsers },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (res.status === 200) {
                setFeedbackMessage('Users Leave Rule updated successfully.');
            } else {
                setFeedbackMessage('Failed to update Users Leave Rule.');
            }
        } catch (err) {
            console.error('Error updating leave rule:', err);
            if (err.response && err.response.data.errors) {
                setValidationErrors(err.response.data.errors);
            } else {
                setFeedbackMessage('An error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className='ep-pd'>
            <div className="ep-li-title">Human Resource</div>

            <form className="ep-form" onSubmit={handleSubmit}>
                <label>Users Leave Rule:</label>
                <div className="user-checkbox-list">
                    {users.map((user) => (
                        <div key={user._id} className="user-checkbox-item">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedUsers.includes(user._id)}
                                    onChange={() => handleCheckboxChange(user._id)}
                                />
                                {user.name} ({user.email})
                            </label>
                        </div>
                    ))}
                </div>  
                and 
                <div className='m-y-divider'></div>

                <button type="submit" className='ep-btn'>Update Leave Rule</button>
            </form>

            <div className='m-y-divider'></div>

            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    {validationErrors.map((error, index) => (
                        <p key={index} className="error">{error.msg}</p>
                    ))}
                </div>
            )}
        </div>
    );
};      

export default HrSetting;