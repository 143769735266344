import axios from 'axios';
import {
    FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATIONS_SEEN_STATUS,
    UPDATE_NOTIFICATION_READ_STATUS
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Get notifications
export const fetchNotifications = () => async (dispatch) => {
    if(localStorage.token) {
        setAuthToken(localStorage.token);
    }

    dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });

    try {
        const res = await axios.get('/api/util/notifications');
        dispatch({
            type: FETCH_NOTIFICATIONS_SUCCESS,
            payload: res.data.notifications
        });
    } catch (error) {
        dispatch({
            type: FETCH_NOTIFICATIONS_FAILURE,
            payload: error.response?.data?.message || 'Error fetching notifications'
        });
    }
};

// Update Notification on Seen 
export const updateNotificationsSeenStatus = () => async (dispatch, getState) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {

        // Backend API call to mark notifications as seen
        await axios.put('/api/util/notifications/mark-seen');
        
        dispatch(fetchNotifications()); 

        dispatch({
            type: UPDATE_NOTIFICATIONS_SEEN_STATUS,
        });

    } catch (error) {
        console.error('Failed to update notifications seen status', error);
    }
};

// Action to mark notification as read
export const updateNotificationReadStatus = (notificationId) => async (dispatch) => {
    console.log('Action Notification ID:', notificationId);
    try {
        await axios.post(`/api/util/${notificationId}/read`);

        dispatch(fetchNotifications()); 
        
        dispatch({
            type: UPDATE_NOTIFICATION_READ_STATUS,
        });
    } catch (err) {
        console.error('Error marking notification as read:', err);
    }
};
