import { FETCH_ROLE, FETCH_ROLE_ERROR } from '../actions/types';

const initialState = {
  roles: [], // To store an array of roles with user IDs
  loading: true, // Indicates loading state
  error: null, // Stores any errors
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLE:
      return {
        ...state,
        roles: action.payload, // Expecting an array of roles
        loading: false,
        error: null, // Reset error on successful fetch
      };
    case FETCH_ROLE_ERROR:
      return {
        ...state,
        error: action.payload, // Store the error message
        loading: false, // Stop loading on error
      };
    default:
      return state; // Return the existing state for other actions
  }
};

export default roleReducer;
