import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchLeaveRecords } from '../../actions/leave';
import Spinner from '../layout/Spinner';

import { BsArrowUpRightCircle } from "react-icons/bs";
import { IoCheckboxOutline } from "react-icons/io5";
import { RxReload } from "react-icons/rx";
import { TbSquareX } from "react-icons/tb";
import { FaCoffee } from "react-icons/fa";

const LeaveRecords = ({ fetchLeaveRecords, leave: { leaves, loading } }) => {
    
    useEffect(() => {
        fetchLeaveRecords();
    }, [fetchLeaveRecords]);

    useEffect(() => {
    }, [leaves]);

    if (loading || !leaves) {
        return <Spinner />;
    }

    // Ensure correct data structure
    const otherLeaves = Array.isArray(leaves) ? leaves : leaves.otherLeaves || [];

    // Sort by the latest `updatedAt` and limit to the first 4
    const sortedLeaves = [...otherLeaves]
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .slice(0, 4); // Only take the latest 4 leaves

    function capitalizeWords(str) {
        return str
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }

    return (
        <Fragment>
            <div className="grid-section-main roboto-regular">
                <div className="gsm-title" style={{ height: '40px' }} >
                    <div className="adm-db-people-title">Leave Records</div>
                    <div><Link to="/leave-setting" className="icon-reset"><BsArrowUpRightCircle /></Link></div>
                </div>

                <div className="teams-gsm-content">
                    {sortedLeaves.length > 0 ? (

                        <div className="teams-user-list">
                            {sortedLeaves.map(leave => (
                                <Link to={`/allleave-records`} key={leave._id} className="teams-user-item">
                                    <div className="lr-logo">
                                        {/* Conditionally render based on leave.status */}
                                        {leave.status === 'Approved' ? (
                                            <IoCheckboxOutline style={{ color: 'rgb(11, 158, 11)' }} />
                                        ) : leave.status === 'Pending' ? (
                                            <RxReload style={{ color: 'rgb(199, 199, 44)' }} />
                                        ) : leave.status === 'Rejected' ? (
                                            <TbSquareX style={{ color: 'red' }} />
                                        ) : null}
                                    </div>
                                    <div className="teams-user-info">
                                        <div>{capitalizeWords(leave.user.name)}</div>
                                        {/* Format the dates to DD/MM/YYYY */}
                                        <div>{new Date(leave.dateFrom).toLocaleDateString('en-GB')} - {new Date(leave.dateTo).toLocaleDateString('en-GB')}</div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="lr-hero">
                            <div>
                                <div className="lr-hero-logo"><FaCoffee /></div>
                                <div className="lr-hero-text">No Records Found</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

LeaveRecords.propTypes = {
    fetchLeaveRecords: PropTypes.func.isRequired,
    leave: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    leave: state.leave
});

export default connect(mapStateToProps, { fetchLeaveRecords })(LeaveRecords);
