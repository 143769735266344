import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentProfile } from '../../actions/profile';
import Spinner from '../layout/Spinner';

import Calendar from '../component/Calendar';
import Team from '../component/Team';
import LeaveDashboard from '../component/LeaveDashboard';
import Navigation from '../component/Navigation.js';
import LeaveRecords from '../component/LeaveRecords.js';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { useSortableGrid } from '../../utils/useSortableGrid';
import SortableItem from '../../utils/SortableItem';

import { LiaHeart } from "react-icons/lia";
import { MdAddCircleOutline } from "react-icons/md";
import { MdOutlineExitToApp } from "react-icons/md";

const Dashboard = ({ getCurrentProfile, auth: { user }, profile: { profile, loading } }) => {
  // Fetch profile on mount
  useEffect(() => {
    getCurrentProfile().catch((err) => console.error('Failed to fetch profile:', err));
  }, [getCurrentProfile]);

  const {
    resizeActive,
    resizeItemId,
    handleDragEnd,
    handleResize,
    handleActivateResize,
    exitResizeMode,
    addComponentToGrid,
    removeComponentFromGrid,
    items,
    layoutLoading
  } = useSortableGrid();
  
  const userComponents = ['calendar', 'team', 'leave', 'navigation'];
  const otherComponents = ['navigation', 'leaverecords'];
  const [selectedCompartment, setSelectedCompartment] = useState('user');
  
  const [greeting, setGreeting] = useState('');

  // State for dropdown visibility
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting('Morning');
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting('Afternoon');
    } else {
      setGreeting('Have a great night');
    }
  }, []);

  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  if (loading && profile === null) {
    return <Spinner />;
  }

  // Check if user object exists
  if (!user) {
    return <Spinner />;
  }

  if (layoutLoading) {
    return <Spinner />;
  }

  const getImageSource = (component) => {
    switch (component) {
      case 'calendar':
        return '/images/widgets/calendar.png';
      case 'team':
        return '/images/widgets/people.png';
      case 'leave':
        return '/images/widgets/leavedashboard.png';
      case 'navigation':
        return '/images/widgets/nav.png';
      case 'leaverecords':
      return '/images/widgets/leaverecord.png';
      default:
        return '';
    }
  };
  
  const getComponentDisplayName = (component) => {
    const componentNames = {
      calendar: 'Calendar',
      team: 'Team',
      leave: 'Leave Dashboard',
      navigation: 'Navigation',
      leaverecords: 'Leave Records',
    };
    return componentNames[component] || component; // Default to `component` if not found
  };
  


  return (
    <main className="user-dashboard roboto-regular no-select">
      <div className='layout-header'>

        <div className="lh-childone">
          {/* <div className="welcome-user">
            <span>{greeting} {capitalizeWords(user.name)}</span>
            <span><LiaHeart /></span>
          </div> */}
        </div>

        <div className="lh-childtwo">

          {/* Resize Exit Btn */}
          {resizeActive && (
              <div onClick={exitResizeMode} className="exit-resize-button">
                <span><MdOutlineExitToApp /></span>
                <span>Resize Mode</span>
              </div>
            )}

          {/* Custom Dropdown */}
          <div className="layout-dropdown" ref={dropdownRef}>
            <div
              className={`dropdown-toggle ${dropdownOpen ? 'active' : ''}`}
              onClick={toggleDropdown}
              aria-expanded={dropdownOpen}
            >
              <span><MdAddCircleOutline /></span>
              <span>Widget</span>
            </div>

            <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>

              <div className="dropdown-compartment"></div>
              <div className="compartment-toggle-buttons">
                <div
                  className={`toggle-button ${selectedCompartment === 'user' ? 'active' : ''}`}
                  onClick={() => setSelectedCompartment('user')}
                >
                  Tools
                </div>
                {user.role === 'admin' && (
                  <div
                    className={`toggle-button ${selectedCompartment === 'other' ? 'active' : ''}`}
                    onClick={() => setSelectedCompartment('other')}
                  >
                    Management
                  </div>
                )}
              </div>


                <div className='internal-layout'>
                  {selectedCompartment === 'user' && (
                    <div className="dropdown-compartment">
                      {userComponents.map((component) => {
                        const imageSrc = getImageSource(component);
                        return (
                          <button
                            key={component}
                            className="dropdown-item"
                            onClick={() => addComponentToGrid(component)}
                          >
                            <div>
                              {imageSrc && <img src={imageSrc} alt={`${component} icon`} className="dropdown-image" />}
                              <span>{getComponentDisplayName(component)}</span>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  )}

                  {selectedCompartment === 'other' && (
                    <div className="dropdown-compartment">
                      {otherComponents.map((component) => {
                        const imageSrc = getImageSource(component);
                        return (
                          <button
                            key={component}
                            className="dropdown-item"
                            onClick={() => addComponentToGrid(component)}
                          >
                            <div>
                              {imageSrc && <img src={imageSrc} alt={`${component} icon`} className="dropdown-image" />}
                              <span>{getComponentDisplayName(component)}</span>
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  )}

                </div>
            
            
            </div>


          </div>
          
        </div>

      </div>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map((item) => item.id)}>
        <div className="masonry-grid">
          {items.length === 0 ? (
            <div className="widget_intro_container">
              <div className="wi_wrapper">
               <img src="/images/widgets/widget_intro.gif" />
              </div>
            </div>
          ) : (
            items.map((item) => (
              <SortableItem
                key={item.id}
                id={item.id}
                size={item.size}
                isResizeActive={resizeActive && resizeItemId === item.id}
                onResize={handleResize}
                onActivateResize={handleActivateResize}
                onRemove={removeComponentFromGrid}
              >
                {item.id.startsWith('calendar') && <Calendar />}
                {item.id.startsWith('team') && <Team />}
                {item.id.startsWith('leaverecords') && <LeaveRecords />}
                {item.id.startsWith('leave') && <LeaveDashboard />}
                {item.id.startsWith('navigation') && <Navigation user={user} />}
        
              </SortableItem>
            ))
          )}
        </div>
      </SortableContext>
    </DndContext>
  </main>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  profile: PropTypes.shape({
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);

