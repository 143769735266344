import React, { Fragment, useState, useEffect } from 'react';
import { fetchPublicHolidays } from '../../actions/leave';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BsArrowUpRightCircle } from "react-icons/bs";

const Calendar = ({ fetchPublicHolidays, publicHolidays, loading }) => {

const [currentTime, setCurrentTime] = useState(new Date());


    useEffect(() => {
        fetchPublicHolidays();
    }, [fetchPublicHolidays]);

  useEffect(() => {
    // Update the time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  // Helper function to get ordinal suffix for the day
  const getOrdinal = (day) => {
    if (day > 3 && day < 21) return "th"; // Handle 11th, 12th, 13th
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  // Arrays for days of the week and months
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthsAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract full date details
  const dayOfWeek = daysOfWeek[currentTime.getDay()];
  const day = currentTime.getDate();
  const month = monthsOfYear[currentTime.getMonth()];
  const year = currentTime.getFullYear();

  // Format the time (e.g., 10 AM)
  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).replace(":00", ""); // Remove ":00" if you don't want minutes displayed

  // Full date (without time): Monday, 6th January 2024
  const formattedFullDate = `${dayOfWeek}, ${day}${getOrdinal(day)} ${month} ${year}`;

  // Abbreviated date (e.g., 17 <br /> Nov)
  const abbreviatedDate = (
    <span>
      {day} <br /> {monthsAbbr[currentTime.getMonth()]}
    </span>
  );

  const getNextHoliday = (holidays) => {
    const today = new Date();
    const futureHolidays = holidays.filter(holiday => new Date(holiday.date) > today);
    futureHolidays.sort((a, b) => new Date(a.date) - new Date(b.date));
    return futureHolidays.length > 0 ? futureHolidays[0] : null;
    };

    const nextHoliday = getNextHoliday(publicHolidays);
    
    return (
        <Fragment>
        <div className="grid-section-main roboto-regular">
                <div className="gsm-title" >
                    <div className="adm-db-people-title">Calendar & Public Holidays</div>
                    <div><Link to="/Public-Holidays" className="icon-reset"><BsArrowUpRightCircle /></Link></div>
                </div>

                <div className="gsm-content">
                    <div className="calendar-fulldate">{formattedFullDate}</div>
                    <div className="calendar-abbreviated">
                        <div className="calendar-display">{abbreviatedDate}</div>
                        <div className="calendar-clock">{formattedTime}</div>
                    </div>
                    {loading ? (
                        <div className="calendar-upcoming"> Loading ...</div>
                    ) : (
                        // Show the next upcoming public holiday
                        <div>
                            {nextHoliday ? (
                                <div className="calendar-upcoming">
                                    <h3>Upcoming Public Holiday:</h3>
                                    <p>
                                        {nextHoliday.name} - {new Date(nextHoliday.date).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric',
                                        })}
                                    </p>
                                </div>
                            ) : (
                                <p>No upcoming public holidays available</p>
                            )}
                        </div>
                    )}
                    <div className="calendar-deco">
                        <img src="/images/ph/cny.jpg" />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    publicHolidays: state.leave.publicHolidays,
    loading: state.leave.loading
});

export default connect(mapStateToProps, {fetchPublicHolidays})(Calendar);
