import {
    FETCH_PUBLIC_HOLIDAYS,
    FETCH_PH_ERROR,
    FETCH_PENDING_LEAVES,
    FETCH_LEAVE_RECORDS,
    FETCH_LEAVES_ERROR,
    SUBMIT_LEAVE_SUCCESS,
    SUBMIT_LEAVE_FAIL,
    CREATE_LEAVE_CORRECTION_REQUEST,
    CREATE_LEAVE_CORRECTION_SUCCESS,
    CREATE_LEAVE_CORRECTION_FAIL,
    LEAVE_APPROVE_REQUEST,
    LEAVE_APPROVE_SUCCESS,
    LEAVE_APPROVE_FAIL,
    LEAVE_REJECT_REQUEST,
    LEAVE_REJECT_SUCCESS,
    LEAVE_REJECT_FAIL
} from '../actions/types';

const initialState = {
    leaves: [],
    publicHolidays: [],
    loading: true,
    error: null,
    leave: null
};

const leaveReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
      case FETCH_PUBLIC_HOLIDAYS:
        return {
            ...state,
            publicHolidays: payload,
            loading: false,
            error: null
        };
      case FETCH_PENDING_LEAVES:
      case FETCH_LEAVE_RECORDS:
      return {
          ...state,
          leaves: payload,
          loading: false,
          error: null
      };
      case SUBMIT_LEAVE_SUCCESS:
        return {
          ...state,
          leaves: payload,
          loading: false,
        };
      case CREATE_LEAVE_CORRECTION_REQUEST:
      case LEAVE_APPROVE_REQUEST:
      case LEAVE_REJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
      case CREATE_LEAVE_CORRECTION_SUCCESS:
      case LEAVE_APPROVE_SUCCESS:
      case LEAVE_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        leave: action.payload,
      };
      case FETCH_PH_ERROR:
      case FETCH_LEAVES_ERROR:
      case SUBMIT_LEAVE_FAIL:
      case CREATE_LEAVE_CORRECTION_FAIL:
      case LEAVE_APPROVE_FAIL:
      case LEAVE_REJECT_FAIL:
        return {
            ...state,
            error: payload,
            loading: false
        };
      default:
        return state;
    }
};       

export default leaveReducer;
