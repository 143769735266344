import React, { useState, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};

const SortableItem = React.memo(({ id, size, isResizeActive, onResize, onActivateResize, onRemove, children }) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id,
    disabled: isResizeActive, // Disable drag when resizing
  });

  const windowWidth = useWindowWidth(); // Get the current window width
  const [boxWidth, setBoxWidth] = useState(size);
  const [userSetWidth, setUserSetWidth] = useState(size);

  useEffect(() => {
    if (userSetWidth > windowWidth - 100) {
      setBoxWidth(windowWidth - 100);
    } else {
      setBoxWidth(userSetWidth);
    }
  }, [windowWidth, userSetWidth]);

  const handleResize = (newWidth) => {
    setUserSetWidth(newWidth);
    setBoxWidth(newWidth);
    onResize(id, newWidth);
  };

  // Breakpoint styles
  const isMobileLayout = windowWidth < 1000; // Apply changes for screens < 1000px

  const style = {
    transform: isResizeActive ? 'none' : CSS.Transform.toString(transform),
    backgroundColor: 'transparent',
    border: isResizeActive ? '2.5px dashed #421ab2' : '1px solid #eee',
    borderRadius: '8px',
    cursor: isResizeActive ? 'default' : 'grab',
    position: 'relative',
    zIndex: isResizeActive ? 99 : 'auto',
    overflow: 'hidden',
    width: isMobileLayout ? '100%' : `${boxWidth}px`,
    height: '420px',
  };

  return (
    <div
      ref={setNodeRef}
      className='resize-container'
      style={style}
      {...(isResizeActive ? {} : { ...attributes, ...listeners })}
      onDoubleClick={() => onActivateResize(id)}
    >
      <ResizableBox
        width={boxWidth}
        height={420}
        axis="x"
        minConstraints={[350, 450]}
        maxConstraints={[600, 450]}
        onResizeStop={(e, data) => handleResize(data.size.width)}
        resizeHandles={isResizeActive ? ['e'] : []}
        handleStyles={{
          e: {
            display: isResizeActive ? 'block' : 'none',
            width: '20px',
            backgroundColor: '#007BFF',
            cursor: 'ew-resize',
          },
        }}
      >
        <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          {children}
        </div>
      </ResizableBox>

      {isResizeActive && (
        <div
          onClick={() => onRemove(id)}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            backgroundColor: '#421ab2',
            color: '#fff',
            border: 'none',
            borderRadius: '20%',
            cursor: 'pointer',
            width: '33px',
            height: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className='layout-removebtn'
        >
          X
        </div>
      )}
    </div>
  );
});

export default SortableItem;
