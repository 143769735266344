// src/action/types.js
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const USERS_ERROR = "USERS_ERROR";
export const FETCH_PUBLIC_HOLIDAYS = "FETCH_PUBLIC_HOLIDAYS";
export const FETCH_PH_ERROR = "FETCH_PH_ERROR";
export const FETCH_PENDING_LEAVES = "FETCH_PENDING_LEAVES";
export const FETCH_LEAVE_RECORDS = "FETCH_LEAVE_RECORDS";
export const FETCH_LEAVES_ERROR = "FETCH_LEAVES_ERROR";
export const PERSONAL_DETAILS_UPDATE_SUCCESS = 'PERSONAL_DETAILS_UPDATE_SUCCESS';
export const PERSONAL_DETAILS_UPDATE_FAIL = 'PERSONAL_DETAILS_UPDATE_FAIL';
export const GET_COMPANY = 'GET_COMPANY';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAIL = "RESETPASSWORD_FAIL";
export const RESETPASSWORD_REQUEST = 'RESETPASSWORD_REQUEST';
export const CLOCK_IN_SUCCESS = 'CLOCK_IN_SUCCESS';
export const CLOCK_IN_FAIL = 'CLOCK_IN_FAIL';
export const CLOCK_OUT_SUCCESS = 'CLOCK_OUT_SUCCESS';
export const CLOCK_OUT_FAIL = 'CLOCK_OUT_FAIL';
export const FETCH_CLOCK_STATE_SUCCESS = 'FETCH_CLOCK_STATE_SUCCESS';
export const FETCH_CLOCK_STATE_FAIL = 'FETCH_CLOCK_STATE_FAIL';
export const BREAK_SUCCESS = 'BREAK_SUCCESS';
export const BREAK_FAIL = 'BREAK_FAIL';
export const LOADING = 'LOADING';
export const SUBMIT_LEAVE_SUCCESS = 'SUBMIT_LEAVE_SUCCESS';
export const SUBMIT_LEAVE_FAIL = 'SUBMIT_LEAVE_FAIL';
export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_ERROR = 'FETCH_ROLE_ERROR';
export const CREATE_LEAVE_CORRECTION_REQUEST = 'CREATE_LEAVE_CORRECTION_REQUEST';
export const CREATE_LEAVE_CORRECTION_SUCCESS = 'CREATE_LEAVE_CORRECTION_SUCCESS';
export const CREATE_LEAVE_CORRECTION_FAIL = 'CREATE_LEAVE_CORRECTION_FAIL';
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const UPDATE_NOTIFICATIONS_SEEN_STATUS = 'UPDATE_NOTIFICATIONS_SEEN_STATUS';
export const UPDATE_NOTIFICATION_READ_STATUS = 'UPDATE_NOTIFICATION_READ_STATUS';
export const LEAVE_APPROVE_REQUEST = 'LEAVE_APPROVE_REQUEST';
export const LEAVE_APPROVE_SUCCESS = 'LEAVE_APPROVE_SUCCESS';
export const LEAVE_APPROVE_FAIL = 'LEAVE_APPROVE_FAIL';
export const LEAVE_REJECT_REQUEST = 'LEAVE_REJECT_REQUEST';
export const LEAVE_REJECT_SUCCESS = 'LEAVE_REJECT_SUCCESS';
export const LEAVE_REJECT_FAIL = 'LEAVE_REJECT_FAIL';



