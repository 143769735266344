import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchLeaveRecords } from '../../actions/leave';
import Spinner from '../layout/Spinner';
import CorrectionModal from './CorrectionModal'; // Import the modal component

import { CiEdit } from "react-icons/ci";

const LeaveRecords = ({ fetchLeaveRecords, leave }) => {
  const { userLeaves = [], loading = false } = leave.leaves || {};
  const [filterStatus, setFilterStatus] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false); 
  const [selectedLeave, setSelectedLeave] = useState(null); // Store selected leave data

  const itemsPerPage = 5; // Number of items per page

  useEffect(() => {
    fetchLeaveRecords();
  }, [fetchLeaveRecords, leave]);

  // Reset currentPage to 1 when filterStatus changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filterStatus]);

  // Scroll to the top whenever the page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Filter leaves based on the selected status
  const filteredLeaves = useMemo(() => 
    userLeaves.filter(
      leave => filterStatus === 'All' || leave.status.toLowerCase() === filterStatus.toLowerCase()
    ), [userLeaves, filterStatus]
  );

  // Sort leaves by createdAt in descending order
  const sortedLeaves = useMemo(() => 
    filteredLeaves.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    ), [filteredLeaves]
  );

  // Calculate the number of pages needed
  const totalPages = Math.ceil(sortedLeaves.length / itemsPerPage);

  // Calculate the index range of items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedLeaves.slice(indexOfFirstItem, indexOfLastItem);

  if (loading) {
    return <Spinner />;
  }

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Function to handle opening the modal
  const openModal = (leave) => {
    setSelectedLeave(leave); // Set the selected leave data
    setShowModal(true); // Show the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedLeave(null);
  };

  const convertTo12HourFormat = (hours, minutes) => {
    let period = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight and noon
  
    // Ensure minutes are always two digits
    let minutes12 = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours12}:${minutes12} ${period}`;
  };
  

  return (
    <Fragment>
      <main className="lr-main roboto-regular">
        <div>
          <select
            className="dropdown-btn"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>

          {currentItems.length > 0 ? (
            <ol className="leave-list">
              {currentItems.map((leave, index) => (
                <li key={leave._id} className="lr-li">
                  <div className="lr-content">

                      {/* Leave Correction Button */}
                      <div
                        className="correction-btn"
                        onClick={() => openModal(leave)} // Open the modal when clicked
                      >
                        <CiEdit />
                      </div>

                    <div>
                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Type of Leave: </span>
                        <span className="lr-c-i-desc">{leave.leaveType}</span>
                      </div>

                      <div className="lr-content-input"> 
  {new Date(leave.correction === 'true' ? leave.correctedLeave.dateFrom : leave.dateFrom).toLocaleDateString() === new Date(leave.correction === 'true' ? leave.correctedLeave.dateTo : leave.dateTo).toLocaleDateString() ? (
    // If From and To are the same, display one date
    <div>
      <span className="lr-c-i-title">Date:</span>
      <span className="lr-c-i-desc">
        {new Date(
          leave.correction === 'true' 
            ? leave.correctedLeave.dateFrom 
            : leave.dateFrom
        ).toLocaleDateString('en-GB', { 
          day: '2-digit', 
          month: '2-digit', 
          year: 'numeric' 
        })}
      </span>
    </div>
  ) : (
    // If From and To are different, show both dates
    <>
      <div>
        <span className="lr-c-i-title">From:</span>
        <span className="lr-c-i-desc">
          {new Date(
            leave.correction === 'true' 
              ? leave.correctedLeave.dateFrom 
              : leave.dateFrom
          ).toLocaleDateString('en-GB', { 
            day: '2-digit', 
            month: '2-digit', 
            year: 'numeric' 
          })}
        </span>
      </div>
      <div>
        <span className="lr-c-i-title">To:</span>
        <span className="lr-c-i-desc">
          {new Date(
            leave.correction === 'true' 
              ? leave.correctedLeave.dateTo 
              : leave.dateTo
          ).toLocaleDateString('en-GB', { 
            day: '2-digit', 
            month: '2-digit', 
            year: 'numeric' 
          })}
        </span>
      </div>
    </>
  )}
</div>


<div className="lr-content-input">
  {leave.correction === 'true'
    ? leave.correctedLeave.daysBetween === 0.5
      ? (
        <>
          <span className="lr-c-i-title">Number of Days: </span>
          <span className="lr-c-i-desc">Half Day</span>
        </>
      )
      : leave.correctedLeave.daysBetween < 1 
        ? (
          <>
            <span className="lr-c-i-title">Time: </span>
            <span className="lr-c-i-desc">
              {convertTo12HourFormat(leave.correctedLeave.timeFrom.hours, leave.correctedLeave.timeFrom.minutes)} - 
              {convertTo12HourFormat(leave.correctedLeave.timeTo.hours, leave.correctedLeave.timeTo.minutes)}
            </span>
          </>
        )
        : (
          <>
            <span className="lr-c-i-title">Number of Days: </span>
            <span className="lr-c-i-desc">
              {leave.correctedLeave.daysBetween} {leave.correctedLeave.daysBetween === 1 ? 'day' : 'days'}
            </span>
          </>
        )
    : leave.daysBetween === 0.5
    ? (
      <>
        <span className="lr-c-i-title">Number of Days: </span>
        <span className="lr-c-i-desc">Half Day</span>
      </>
    )
    : leave.daysBetween < 1
      ? (
        <>
          <span className="lr-c-i-title">Time: </span>
          <span className="lr-c-i-desc">
            {convertTo12HourFormat(leave.timeFrom.hours, leave.timeFrom.minutes)} - 
            {convertTo12HourFormat(leave.timeTo.hours, leave.timeTo.minutes)}
          </span>
        </>
      )
      : (
        <>
          <span className="lr-c-i-title">Number of Days: </span>
          <span className="lr-c-i-desc">
            {leave.daysBetween} {leave.daysBetween === 1 ? 'day' : 'days'}
          </span>
        </>
      )
  }
</div>


        


                      {(leave.correction === 'true' ? leave.correctedLeave : leave).halfDayOption && (
                        <div className="lr-content-input">
                          <span className="lr-c-i-title">From: </span>
                          <span className="lr-c-i-desc">
                            {(leave.correction === 'true' 
                              ? leave.correctedLeave.halfDayOption || 'N/A' 
                              : leave.halfDayOption) || 'N/A'}
                          </span>
                        </div>
                      )}


                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Reason: </span>
                        <span className="lr-c-i-desc">
                          {leave.correction === 'true' 
                            ? leave.correctedLeave.reason || 'No reason provided' 
                            : leave.reason || 'No reason provided'}
                        </span>
                      </div>

                      <div className="lr-content-input">
                        <span className="lr-c-i-title">Applied on: </span>
                        <span className="lr-c-i-desc">{new Date(leave.createdAt).toLocaleDateString('en-GB')}</span>
                      </div>

                      <div className="lr-status">
                        <div
                          className="roboto-bold"
                          style={{
                            backgroundColor:
                              leave.status === 'Approved'
                                ? '#4CAF50' // Green
                                : leave.status === 'Pending'
                                ? '#FFC107' // Yellow
                                : leave.status === 'Rejected'
                                ? '#d13e38' // Red
                                : 'initial', // Default background color
                            color: leave.status === 'Rejected' ? '#EEEEEE' : '#000000',
                          }}
                        >
                          {leave.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          ) : (
            <p className="nl-alert">No Existing Leave Record Found</p>
          )}

          {/* Pagination Controls */}
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="pg-action-btn"
              aria-label="Previous page"
            >
              &#x27F5;
            </button>
            {pageNumbers.map(number => (
              <button
                key={number}
                onClick={() => setCurrentPage(number)}
                className={number === currentPage ? 'active' : ''}
                aria-label={`Page ${number}`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pg-action-btn"
              aria-label="Next page"
            >
              &#x27F6;
            </button>
          </div>
        </div>

          {/* Correction Modal */}
          {showModal && <CorrectionModal leave={selectedLeave} onClose={closeModal} />}

      </main>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  leave: state.leave
});

export default connect(mapStateToProps, { fetchLeaveRecords })(LeaveRecords);
