import React from 'react'
import { Link } from 'react-router-dom';

import { BiGridAlt } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";

const Navigation = () => {

    return (
        <div className='lp-nav-container'>
            <div className='lp-nav-innercont'>
                <div className="lp-nav-logo">
                    <img src='/images/krafsync_logo.png' alt='krafsync'/>
                </div>
                <div className='lp-nav-buttons'>
                    <Link to='/Login' className='lp-nav-login lp-nav-btns'>Login</Link>
                    <Link to='/Register' className='lp-nav-register lp-nav-btns'>
                    <span>Get Started</span>
                    <span className='lp-nav-register-arw'><FaArrowRightLong /></span>
                    </Link>
                    <div className='lp-nav-btns lp-nav-gridicon'><BiGridAlt /></div>
                </div>
            </div>
        </div>
    )
}

export default Navigation;
