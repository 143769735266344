// src/reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import users from './users';
import leave from './leave';
import company from './company';
import reset from './reset';
import attendance from './attendance';
import role from './role';
import notification from './notification';
// Import your individual reducers here
// import exampleReducer from './exampleReducer';

const rootReducer = combineReducers({
    alert,
    auth,
    profile,
    users,
    leave,
    company,
    reset,
    attendance,
    role,
    notification,
});

export default rootReducer;
