import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { fetchNotifications, updateNotificationsSeenStatus, updateNotificationReadStatus } from '../../actions/notification';
import { Link, useNavigate } from 'react-router-dom';
import { FaBell } from "react-icons/fa";
import Spinner from './Spinner';

const Navbar = ({ auth: { isAuthenticated, loading, user }, notifications, fetchNotifications, updateNotificationsSeenStatus, updateNotificationReadStatus, logout }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isCentered, setIsCentered] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');
    const dropdownRef = useRef(null);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const notificationDropdown = useRef(null);

    // Function to load avatar
    const loadAvatar = () => {
        if (user) {
            if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
                setAvatarUrl(`https:${user.avatar}`);
            } else if (user.avatarUrl) {
                setAvatarUrl(user.avatarUrl);
            } else {
                setAvatarUrl('/path-to-default-avatar.jpg');
            }
        } else {
            setAvatarUrl('/path-to-default-avatar.jpg');
        }
    };

        // Fetch notifications when the user is authenticated
        useEffect(() => {
            if (isAuthenticated) {
                fetchNotifications();
            }
        }, [isAuthenticated, fetchNotifications]);

        // Load avatar dynamically based on user changes
        useEffect(() => {
            loadAvatar();
        }, [user]);

        useEffect(() => {
            if (notificationOpen) {
                updateNotificationsSeenStatus();
            }
        }, [notificationOpen]);  
        
        useEffect(() => {
            if (notificationOpen) {
              // Focus on the dropdown
              notificationDropdown.current.focus();
          
              // Disable body scroll
              document.body.classList.add('no-scroll');
            } else {
              // Re-enable body scroll when dropdown is closed
              document.body.classList.remove('no-scroll');
            }
          }, [notificationOpen]);          

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setIsCentered(!isCentered);
    };

    const toggleNotificationDropdown = () => {
        setNotificationOpen(!notificationOpen);

        // If the dropdown is opened, mark all notifications as seen
        if (!notificationOpen) {
            updateNotificationsSeenStatus();
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsCentered(false);
        }
        if (notificationDropdown.current && !notificationDropdown.current.contains(event.target)) {
            setNotificationOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNotificationClick = (notificationId) => {
        updateNotificationReadStatus(notificationId);
    };
    

    const renderNotifications = () => {
        // Only show spinner if notifications are being loaded, not when just marking as seen
        if (notifications.loading && !notifications.notifications.length) {
            return <div className="notification-dropdown-general"><Spinner /></div>;
        }
    
        if (notifications.error) {
            return <div className="notification-dropdown-general"><Spinner /></div>;
        }
    
        if (notifications.notifications.length === 0) {
            return <div className="notification-dropdown-general">No notifications available.</div>;
        }
    
        return (
            <ul>
                {notifications.notifications.map((notification) => (
                    <li key={notification._id} className={notification.read ? 'read' : 'unread'}>
                        <Link to={notification.redirectUrl} className="notification-link"
                         onClick={() => handleNotificationClick(notification._id)} 
                        >
                            {notification.message}
                        </Link>
                    </li>
                ))}
            </ul>      
        );
    };
    
    // Count unread notifications
    const unreadNotifications = notifications.notifications.filter(notification => !notification.seen);
    const unreadCount = unreadNotifications.length;

    const authLinks = (
        <Fragment>
            <div className="nav-right-wrapper no-select">
                <div className="dropdown-container" ref={notificationDropdown}>
                    <div
                        className="notification-pill"
                        onClick={toggleNotificationDropdown}
                    >
                        <FaBell />
                        {unreadCount > 0 && (
                            <div className="notification-badge">{unreadCount}</div> 
                        )}
                    </div>
                    {notificationOpen && (
                        <div className="dropdown-menu notification-dropdown-menu">
                            {renderNotifications()}
                        </div>
                    )}
                </div>

                <div className="dropdown-container" ref={dropdownRef}>
                    <div
                        className={`pill ${isCentered ? 'center' : ''}`}
                        onClick={toggleDropdown}
                    >
                        <img
                            src={avatarUrl}
                            alt="User"
                            className="nav-userimg"
                        />
                        <div className="pill-greeting">Hello!</div>
                        <div className="pill-arrow">
                            <img src="/images/pill-arrow.ico" alt="arrow" />
                        </div>
                    </div>
                    {isOpen && (
                        <div className="dropdown-menu">
                            <ul>
                                <li><Link to={`/user-profile/${user._id}`} className="dm-link">Profile</Link></li>
                                <li><Link to={`/edit-profile/${user._id}`} className="dm-link">Settings</Link></li>
                                <li onClick={handleLogout}>Log Out</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );

    const guestLinks = (
        <div></div>
    );

    return (
        <nav className='roboto-bold no-select'>
            <div className="nav-left">
                <img src="/images/krafsynclogo.png" alt="krafsync" />
            </div>
            <div className="nav-right">
                {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    notifications: state.notification
});

export default connect(mapStateToProps, { logout, fetchNotifications, updateNotificationsSeenStatus, updateNotificationReadStatus })(Navbar);
