import {
    FETCH_NOTIFICATIONS_REQUEST,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATIONS_SEEN_STATUS,
    UPDATE_NOTIFICATION_READ_STATUS
} from '../actions/types';

const initialState = {
    notifications: [],
    loading: false,
    error: null
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: action.payload
            };
        case FETCH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case UPDATE_NOTIFICATIONS_SEEN_STATUS:
        return {
            ...state,
        };
        case UPDATE_NOTIFICATION_READ_STATUS:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default notificationReducer;
