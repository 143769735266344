import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchPendingLeaves, fetchPublicHolidays, approveLeave, rejectLeave } from '../../actions/leave';
import Spinner from '../layout/Spinner';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

import { FiLink } from "react-icons/fi";

const LeaveApplication = () => {
  const dispatch = useDispatch();
  const leaveRecords = useSelector(state => Array.isArray(state.leave.leaves) ? state.leave.leaves : []);
  const publicHolidays = useSelector(state => Array.isArray(state.leave.publicHolidays) ? state.leave.publicHolidays : []);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [tooltipType, setTooltipType] = useState('');
  const tooltipRef = useRef(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch data and set isLoading to false when done
    const fetchData = async () => {
        try {
            await dispatch(fetchPendingLeaves());
            await dispatch(fetchPublicHolidays());
        } finally {
            setIsLoading(false);
        }
    };
    fetchData();
}, [dispatch]);

const loadAvatar = (user) => {
  if (user) {
      if (user.avatar && user.avatar.startsWith('//www.gravatar.com/avatar/')) {
          // Use the Gravatar URL directly
          return `https:${user.avatar}`;
      } else if (user.avatarUrl) {
          // Use the presigned URL directly
          return user.avatarUrl;
      } else {
          // Default avatar if no avatar URL is present
          return '/path-to-default-avatar.jpg';
      }
  }
  // Default avatar if no user
  return '/path-to-default-avatar.jpg';
};


  // Tooltip positioning function that can be used across events
const calculateTooltipPosition = (targetElement, containerElement) => {
  const containerRect = containerElement.getBoundingClientRect();
  const targetRect = targetElement.getBoundingClientRect();

  return {
    top: targetRect.bottom - containerRect.top + 10, // Position below element
    left: targetRect.left - containerRect.left + targetRect.width / 2, // Center tooltip
  };
};

const handleTileMouseEnter = (event, leaveRecord) => {
  setTooltipContent(leaveRecord);
  setTooltipType('leave');
  setTooltipVisible(true);

  const containerElement = document.querySelector('.calendar-container');
  const position = calculateTooltipPosition(event.currentTarget, containerElement);
  setTooltipPosition(position);
};

const handleTooltipVisibility = (event, holiday) => {
  setTooltipContent(holiday);
  setTooltipType('holiday');
  setTooltipVisible(true);

  const containerElement = document.querySelector('.calendar-container');
  const position = calculateTooltipPosition(event.currentTarget, containerElement);
  setTooltipPosition(position);
};

// Reset tooltip visibility on leave
const handleTileMouseLeave = () => {
  setTooltipVisible(false);
  setTooltipType('');
};


const handleTooltipHide = () => {
  setTooltipVisible(false);
  setTooltipType('');
};

  const getTooltipContent = () => {
    if (!tooltipContent) return null;
    if (tooltipType === 'holiday') {
      const { name, date } = tooltipContent;
      return (
        <div>
          <p><strong>Holiday:</strong> {name}</p>
          <p><strong>Date:</strong> {new Date(date).toLocaleDateString()}</p>
        </div>
      );
    } else if (tooltipType === 'leave') {
      const { user, reason, status, dateFrom, dateTo } = tooltipContent;
      return (
        <div>
          <p><strong>Leave Application</strong></p>
          <br />
          <p><strong>Employee:</strong> {user.name}</p>
          <p><strong>Date From:</strong> {new Date(dateFrom).toLocaleDateString()}</p>
          <p><strong>Date To:</strong> {new Date(dateTo).toLocaleDateString()}</p>
          <p><strong>Status:</strong> {status}</p>
        </div>
      );
    }
    return null;
  };              

  const getDateRange = (startDate, endDate) => {
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);

    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);

    const dates = [];
    let currentDate = new Date(start);

    if (start.getTime() === end.getTime()) {
      dates.push(start.toISOString().split('T')[0]);
    } else {
      while (currentDate < end) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    return dates;
  };

  const getLeaveRecordsForDate = (date) => {
    const dateString = date.toISOString().split('T')[0];
    return leaveRecords.filter(leave => {
      const dateRange = getDateRange(leave.dateFrom, leave.dateTo);
      return dateRange.includes(dateString);
    });
  };

  const isPublicHoliday = (date) => {
    // Convert the date to the local time zone
    const localDateString = date.toLocaleDateString(); // Gets the date in local format
    return publicHolidays.find(holiday => {
        const holidayDate = new Date(holiday.date).toLocaleDateString(); // Assuming holiday.date is in ISO format
        return holidayDate === localDateString; // Compare both dates in local format
    });
};

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      if (isPublicHoliday(date)) {
        return 'public-holiday';
      }
    }
    return null;
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
        const leaveRecordsForDate = getLeaveRecordsForDate(date);
        const publicHoliday = isPublicHoliday(date);

        return (
            <div>
                {publicHoliday && (
                    <span
                        className="public-holiday-text"
                        onMouseEnter={(e) => handleTooltipVisibility(e, publicHoliday)}
                        onMouseLeave={handleTooltipHide}
                        aria-label="Public Holiday"
                    >
                        Public Holiday
                    </span>
                )}
                {leaveRecordsForDate.length > 0 && (
                    <div className="leave-indicators">
                        {leaveRecordsForDate.map((record) => (
                            <img
                                key={record.user._id} // Use a unique identifier if available
                                src={loadAvatar(record.user)} // Use loadAvatar to get the correct URL
                                alt={`User ${record.user.name}`}
                                className="user-image"
                                onMouseEnter={(e) => handleTileMouseEnter(e, record)}
                                onMouseLeave={handleTileMouseLeave}
                                aria-label={`User ${record.user.name}`}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }
    return null;
};

  // Check if leaveRecords is not an array and show spinner if true
  if (!Array.isArray(leaveRecords)) {
    return <Spinner />;
  }

  const formattedDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedTime = new Date().toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  const convertTo12HourFormat = (hours, minutes) => {
    let period = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Handle midnight and noon
  
    // Ensure minutes are always two digits
    let minutes12 = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours12}:${minutes12} ${period}`;
  };
  

  const handleApprove = async (e, leaveId) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
        await dispatch(approveLeave(leaveId));
    } catch (error) {
        console.error('Error approving leave:', error);
    } finally {
        setIsSubmitting(false);
    }
};

const handleReject = async (e, leaveId) => {
  e.preventDefault();

  if (isSubmitting) return;

  setIsSubmitting(true);

  try {
      await dispatch(rejectLeave(leaveId));
  } catch (error) {
      console.error('Error approving leave:', error);
  } finally {
      setIsSubmitting(false);
  }
};
  
  const handleCorrectionTrue = async (e, leaveId) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    setAuthToken(localStorage.getItem('token'));

    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    try {
      const res = await axios.put(`/api/leave/correction-approve/${leaveId}`, {}, config);

      if (res.status === 200 || res.status === 201) {
        setSuccess('Leave Correction Request Successful');
        setError('');
        window.location.reload();
      } else {
        setError('System Error, Kindly contact our Tech Support');
      }
    } catch (error) {
      console.error(error);
      setError('Error updating Leave');  
    } finally {
      setIsSubmitting(false);           
    }
  };                            

  const handleCorrectionReject = async (e, leaveId) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    setAuthToken(localStorage.getItem('token'));

    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    };                

    try {
      const res = await axios.put(`/api/leave/correction-reject/${leaveId}`, {}, config);

      if (res.status === 200 || res.status === 201) {
        setSuccess('Leave Rejected Successfully');
        setError('');
        window.location.reload();
      } else {
        setError('System Error, Kindly contact our Tech Support');
      }
    } catch (error) {
      console.error(error);
      setError('Error updating Leave');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
                <Spinner />
            ) : (
        <main className='roboto-regular'>
          <div className='error-msg roboto-bold'>
            {error && <p style={{ color: 'red' }} className='roboto-bold'>{error}</p>}
            {success && <p style={{ color: 'green' }} className='roboto-bold'>{success}</p>}
          </div>
          
          <div className='container'>
            <div className="leave-application-container">
              <div className="leave-records">
                {leaveRecords.filter(leave => leave.status === 'Pending').length > 0 ? (
                  leaveRecords.filter(leave => leave.status === 'Pending').map((leave) => (

                    <div key={leave._id} className="leave-record">
                      <div className="lm-data-cont">
                        <div className="lm-cont-l">
                          <img src={loadAvatar(leave.user)} alt="img" />
                        </div>
                        <div className="lm-cont-r">
                          <h3>{leave.user.name}</h3>
                          <br />
                      
                          <p><strong>Leave:</strong><span>{leave.leaveType}</span></p>

                          {leave.correction === 'true'
                              ? new Date(leave.correctedLeave.dateFrom).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                }) ===
                                new Date(leave.correctedLeave.dateTo).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                                ? (
                                  <p>
                                    <strong>Date:</strong>
                                    <span>
                                      {new Date(leave.correctedLeave.dateFrom).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                      })}
                                    </span>
                                  </p>
                                )
                                : (
                                  <>
                                    <p>
                                      <strong>From:</strong>
                                      <span>
                                        {new Date(leave.correctedLeave.dateFrom).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric',
                                        })}
                                      </span>
                                    </p>
                                    <p>
                                      <strong>To:</strong>
                                      <span>
                                        {new Date(leave.correctedLeave.dateTo).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric',
                                        })}
                                      </span>
                                    </p>
                                  </>
                                )
                              : new Date(leave.dateFrom).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                }) ===
                                new Date(leave.dateTo).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })
                              ? (
                                <p>
                                  <strong>Date:</strong>
                                  <span>
                                    {new Date(leave.dateFrom).toLocaleDateString('en-GB', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                    })}
                                  </span>
                                </p>
                              )
                              : (
                                <>
                                  <p>
                                    <strong>From:</strong>
                                    <span>
                                      {new Date(leave.dateFrom).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                      })}
                                    </span>
                                  </p>
                                  <p>
                                    <strong>To:</strong>
                                    <span>
                                      {new Date(leave.dateTo).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                      })}
                                    </span>
                                  </p>
                                </>
                              )}


                          <p>
                          <strong style={{ display: (leave.correction === 'true' ? leave.correctedLeave.daysBetween : leave.daysBetween) >= 0.5 ? 'inline' : 'none' }}>
                            Duration:
                          </strong>
                          {((leave.correction === 'true' ? leave.correctedLeave.daysBetween : leave.daysBetween) >= 0.5) && (
                            <span>
                              {leave.correction === 'true' 
                                ? leave.correctedLeave.daysBetween 
                                : leave.daysBetween}{' '}
                              {leave.correction === 'true' 
                                ? leave.correctedLeave.daysBetween > 1 
                                  ? 'days' 
                                  : 'day'
                                : leave.daysBetween > 1 
                                  ? 'days' 
                                  : 'day'}
                            </span>
                          )}
                        </p>


                        {leave.correction === 'true' 
                          ? leave.correctedLeave.halfDayOption !== null && leave.correctedLeave.halfDayOption !== "" 
                            ? (
                              <p>
                                <strong>Time:</strong>
                                <span>{leave.correctedLeave.halfDayOption}</span>
                              </p>
                            ) 
                            : leave.correctedLeave.halfDayOption === "" 
                              ? (
                                <p>
                                  <strong>Time:</strong>
                                  <span>
                                    {`${convertTo12HourFormat(leave.correctedLeave.timeFrom.hours, leave.correctedLeave.timeFrom.minutes)} - ${convertTo12HourFormat(leave.correctedLeave.timeTo.hours, leave.correctedLeave.timeTo.minutes)}`}
                                  </span>
                                </p>
                              )
                              : <span></span>
                          : leave.halfDayOption !== null && leave.halfDayOption !== "" 
                            ? (
                              <p>
                                <strong>Time:</strong>
                                <span>{leave.halfDayOption}</span>
                              </p>
                            ) 
                            : leave.halfDayOption === "" 
                              ? (
                                <p>
                                  <strong>Time:</strong>
                                  <span>
                                    {`${convertTo12HourFormat(leave.timeFrom.hours, leave.timeFrom.minutes)} - ${convertTo12HourFormat(leave.timeTo.hours, leave.timeTo.minutes)}`}
                                  </span>
                                </p>
                              )
                              : <span></span>
                        }



                          <p>
                            <strong>Reason:</strong>
                            <span>
                              {leave.correction === 'true' 
                                ? leave.correctedLeave.reason 
                                : leave.reason}
                            </span>
                          </p>


                          {new Date(
                            leave.correction === 'true'
                              ? leave.correctedLeave.dateFrom
                              : leave.dateFrom
                          ).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          }) !==
                          new Date(
                            leave.correction === 'true'
                              ? leave.correctedLeave.dateTo
                              : leave.dateTo
                          ).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          }) && (
                            <p>
                              Resume Work on{' '}
                              {new Date(
                                leave.correction === 'true'
                                  ? leave.correctedLeave.dateTo
                                  : leave.dateTo
                              ).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </p>
                          )}



                          {leave.file ? (
                            <a href={leave.fileName} target="_blank" rel="noopener noreferrer" className='lm-attachment'>
                              <span><FiLink /></span> <span>View Attachment</span>
                            </a>
                          ) : null}
                        </div>
                      </div>
                                  
                      <div className="lm-btn-cont">
                      <button
                        className="approve-button"
                        onClick={(e) => {
                          if (leave.correction === 'true') {
                            const correctedLeaveId = leave.correctedLeave._id;
                            leave._id = correctedLeaveId; // Update leave._id
                            handleCorrectionTrue(e, correctedLeaveId);
                          } else {
                            const leaveId = leave._id;
                            handleApprove(e, leaveId);
                          }
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Approving...' : 'Approve Leave'}
                      </button>

                                  
                      <button
                        className="reject-button"
                        onClick={(e) => {
                          if (leave.correction === 'true') {
                            const correctedLeaveId = leave.correctedLeave._id;
                            leave._id = correctedLeaveId; // Update leave._id
                            handleCorrectionReject(e, correctedLeaveId);
                          } else {
                            const leaveId = leave._id;
                            handleReject(e, leaveId);
                          }
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Rejecting...' : 'Reject Leave'}
                      </button>

                      </div>             
                      
                      {leave.leaveType === 'annual' ? (
                        <p className="user-leave-data roboto-bold">
                          Remaining {leave.leaveType} Leave: &nbsp; 
                          {leave.user.leave.annual % 1 === 0 ? leave.user.leave.annual : leave.user.leave.annual.toFixed(1)}
                        </p>
                      ) : (
                        <span></span>
                      )}

                      {leave.leaveType === 'medical' ? (<p className="user-leave-data roboto-bold">Remaining {leave.leaveType} Leave: &nbsp; {leave.user.leave.medical}</p>) : (<span></span>)}
                      {leave.leaveType === 'emergency' ? (<p className="user-leave-data roboto-bold">Remaining Annual Leave: &nbsp; {leave.user.leave.annual}</p>) : (<span></span>)}
                      {leave.leaveType === 'maternity' ? (<p className="user-leave-data roboto-bold">Remaining {leave.leaveType} Leave: &nbsp; {leave.user.leave.maternity}</p>) : (<span></span>)}
                      {leave.leaveType === 'hospitalisation' ? (<p className="user-leave-data roboto-bold">Remaining {leave.leaveType} Leave: &nbsp; {leave.user.leave.hospitalisation}</p>) : (<span></span>)}
                    </div>

                  ))    
                ) : (        
                  <Fragment> 
                    <main className='roboto-regular'>
                      <div className="leave-application-container">
                        <div className="no-leave-container">
                          <div>{formattedDate} {formattedTime}</div>
                          <div>No Pending Leave Application</div>
                        </div> 
                      </div>
                    </main>
                  </Fragment> 
                )}       
              </div>
              <div className="calendar-container">
                <Calendar
                  tileClassName={tileClassName}
                  tileContent={tileContent}
                />
                {tooltipVisible && (
                  <div
                    className="tooltip"
                    ref={tooltipRef}
                    style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
                  >
                    {getTooltipContent()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        )}
    </Fragment>  
  );
};   

export default LeaveApplication;
