import axios from 'axios';
import {
    FETCH_PUBLIC_HOLIDAYS,
    FETCH_PH_ERROR,
    FETCH_PENDING_LEAVES,
    FETCH_LEAVE_RECORDS,
    FETCH_LEAVES_ERROR,
    SUBMIT_LEAVE_SUCCESS,
    SUBMIT_LEAVE_FAIL,
    CREATE_LEAVE_CORRECTION_REQUEST,
    CREATE_LEAVE_CORRECTION_SUCCESS,
    CREATE_LEAVE_CORRECTION_FAIL,
    LEAVE_APPROVE_REQUEST,
    LEAVE_APPROVE_SUCCESS,
    LEAVE_APPROVE_FAIL,
    LEAVE_REJECT_REQUEST,
    LEAVE_REJECT_SUCCESS,
    LEAVE_REJECT_FAIL
} from './types';

import {fetchRole} from './auth'
import {fetchNotifications} from './notification';
import setAuthToken from '../utils/setAuthToken';
import ToastService from '../utils/toastConfig';

import socket from '../utils/socketService'; 

// Fetch Public Holidays
export const fetchPublicHolidays = () => async dispatch => {
    try {
        // Set token in headers if available
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const res = await axios.get('/api/leave/publicholidays');
        dispatch({
            type: FETCH_PUBLIC_HOLIDAYS,
            payload: res.data.holidays
        });
    } catch (err) {
        console.error(err);
        dispatch({
            type: FETCH_PH_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

// Fetch Pending Leaves 
export const fetchPendingLeaves = () => async dispatch => {
    try {
        // Set token in headers if available
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const res = await axios.get('/api/leave/admin');
        dispatch({
            type: FETCH_PENDING_LEAVES,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
        dispatch({
            type: FETCH_LEAVES_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

// Fetch Pending Leaves 
export const fetchLeaveRecords = () => async dispatch => {
    try {
        
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        const res = await axios.get('/api/leave/record');
        dispatch({
            type: FETCH_LEAVE_RECORDS,
            payload: res.data
        });
    } catch (err) {
        console.error(err);
        dispatch({
            type: FETCH_LEAVES_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

// Leave Submission Logic
export const submitLeaveApplication = (formData, navigate) => async (dispatch, getState) => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      // Retrieve companyId from FormData
      const companyId = formData.get('companyId');
      console.log('Company ID:', companyId); 
  
      // Submit leave application
      const res = await axios.post('/api/leave/apply-leave', formData, config);
  
      // Check for unexpected issues in the response
      if (!res.data || res.status !== 200) {
        throw new Error('Unexpected response from server');
      }
  
      // Dispatch success action
      dispatch({
        type: SUBMIT_LEAVE_SUCCESS,
        payload: res.data,
      });

      // Emit a notification to the server after a successful submission
      socket.emit('sendNotification', {
        company: companyId,
        message: `You have a leave request from ${formData.get('name')}`,
      });
      
      // Success toast notification
      ToastService.success('Leave Request Submitted Successfully!');
  
      // Fetch roles
      await dispatch(fetchRole());   
      await dispatch(fetchNotifications()); 
  
    } catch (err) {
      // Log the error for debugging
      console.error('Error during leave submission:', err);
  
      // Check if the error is from the server
      const errorMessage = err.response?.data?.message || 'Error submitting leave';
  
      // Dispatch failure action
      dispatch({
        type: SUBMIT_LEAVE_FAIL,
        payload: {
          msg: errorMessage,
          status: err.response?.status || 500,
        },
      });       
  
      // Show alert for specific error
      ToastService.error(`(${errorMessage})!`); 
    }
  };          
  
  // Action to submit leave correction data
  export const submitLeaveCorrection = (correctionData) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_LEAVE_CORRECTION_REQUEST });

      // Replace with the appropriate API endpoint
      const response = await axios.post(`/api/leave/correct-leave`, correctionData);

      // Dispatch success if API call is successful
      dispatch({
        type: CREATE_LEAVE_CORRECTION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_LEAVE_CORRECTION_FAIL,
        payload: error.response ? error.response.data : error.message,
      }); 
    }
  };

  // Action to approve leave and refresh data
export const approveLeave = (leaveId) => async (dispatch, getState) => {
  dispatch({ type: LEAVE_APPROVE_REQUEST });

  try {

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

      const res = await axios.put(`/api/leave/approve/${leaveId}`, {}, config);

      dispatch({ type: LEAVE_APPROVE_SUCCESS, payload: res.data });

       // Emit a notification to the server after a successful submission
       socket.emit('sendUserNotification', {
        leave: leaveId,
        message: `Leave Approved!`,
      });

      // Success toast notification
      ToastService.success('Leave Approved!');

      // Refresh pending leaves and public holidays
      await dispatch(fetchNotifications()); 
      await dispatch(fetchPendingLeaves());
      await dispatch(fetchPublicHolidays());
  } catch (error) {
      console.error(error);
      dispatch({
          type: LEAVE_APPROVE_FAIL,
          payload: error.response?.data?.message || 'Error updating Leave',
      });
  }
};

  // Action to reject leave and refresh data
  export const rejectLeave = (leaveId) => async (dispatch, getState) => {
    dispatch({ type: LEAVE_REJECT_REQUEST });
  
    try {
  
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
  
        const res = await axios.put(`/api/leave/reject/${leaveId}`, {}, config);
  
        dispatch({ type: LEAVE_REJECT_SUCCESS, payload: res.data });
  
         // Emit a notification to the server after a successful submission
         socket.emit('sendUserNotification', {
          leave: leaveId,
          message: `Leave Rejected!`,
        });
  
        // Success toast notification
        ToastService.error('Leave Rejected!');
  
        // Refresh pending leaves and public holidays
        await dispatch(fetchNotifications()); 
        await dispatch(fetchPendingLeaves());
        await dispatch(fetchPublicHolidays());
    } catch (error) {
        console.error(error);
        dispatch({
            type: LEAVE_REJECT_FAIL,
            payload: error.response?.data?.message || 'Error updating Leave',
        });
    }
  };

  