import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsers } from '../../actions/users';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';

import PeopleIcon from '../component/PeopleIcon.js';
import Recruitment from '../component/Recruitment.js'
import Calendar from '../component/Calendar.js'
import AdminNavigation from '../component/AdminNavigation.js';
import LeaveRecords from '../component/LeaveRecords.js';
import LeaveSummary from '../component/LeaveSummary.js';

const Admin = ({ getUsers, auth: { user }, users: { users, loading } }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (loading) {
    return <Spinner />;
  }

  const handleUserClick = (userId) => {
    navigate(`/admin/${userId}`);
  };

  return (
    <Fragment>
      <main className="adm-dbmain roboto-regular">
        <div className="adm-grid">

          <div className="adm-grid-main-wrapper">
            <div className='adm-grid-main'>
              <AdminNavigation user={user}/>
              <Recruitment />
            
            </div>
          </div>

        </div>
      </main>
    </Fragment>
  );
};

Admin.propTypes = {
  getUsers: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  users: PropTypes.shape({
    users: PropTypes.array,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

export default connect(mapStateToProps, { getUsers })(Admin);
