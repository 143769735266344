import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastService = {
  success: (message, options = {}) => {
    toast.success(message, { position: 'bottom-right', autoClose: 5000, ...options });
  },
  error: (message, options = {}) => {
    toast.error(message, { position: 'bottom-right', autoClose: 5000, ...options });
  },
  info: (message, options = {}) => {
    toast.info(message, { position: 'bottom-right', autoClose: 5000, ...options });
  },
  warning: (message, options = {}) => {
    toast.warning(message, { position: 'bottom-right', autoClose: 5000, ...options });
  },
};

export const ToastContainerWrapper = () => {
  return <ToastContainer />;
};

export default ToastService;

