import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Navsection = ({ auth: { user } }) => {
  return (
    <div className="navSection roboto-regular no-select">
      <div className="inner-navSection">
        <Link to="/dashboard" className="navSection-items">
          <span className="navSection-item-img">
            <img src="/images/categorize-24.ico" alt="nav-tag" />
          </span>
          <span className="navSection-text">Overview</span>
        </Link>
        
        <Link to="/people" className="navSection-items">
          <span className="navSection-item-img">
            <img src="/images/group-24.ico" alt="nav-tag" />
          </span>
          <span className="navSection-text">People</span>
        </Link>

        <Link to="/comingsoon" className="navSection-items">
          <span className="navSection-item-img">
            <img src="/images/time-4-24.ico" alt="nav-tag" />
          </span>
          <span className="navSection-text">Attendance</span>
        </Link>

        {/* Check if user is available before rendering this link */}
        {user && user._id && (
          <Link to={`/leave-form/${user._id}`} className="navSection-items">
            <span className="navSection-item-img">
              <img src="/images/paper-24.ico" alt="nav-tag" />
            </span>
            <span className="navSection-text">Leaves</span>
          </Link>
        )}

        <Link to="/comingsoon" className="navSection-items">
          <span className="navSection-item-img">
            <img src="/images/list-ingredients-24.ico" alt="nav-tag" />
          </span>
          <span className="navSection-text">To Do</span>
        </Link>

        <Link to="/comingsoon" className="navSection-items">
          <span className="navSection-item-img">
            <img src="/images/network-24.ico" alt="nav-tag" />
          </span>
          <span className="navSection-text">Projects</span>
        </Link>

         {/* Check if user is available before rendering this link */}
         {user && user._id && (
        <Link to={`/edit-profile/${user._id}`} className="navSection-items">
          <span className="navSection-item-img">
            <img src="/images/settings-4-24.ico" alt="nav-tag" />
          </span>
          <span className="navSection-text">Settings</span>
        </Link>
        )}
        
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Navsection);
