import React, { useEffect, Fragment, useState } from 'react';
import { getUser } from '../../actions/users';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';

const UpdateLeaveForm = ({ getUser, auth, user: { user, loading } }) => {
  const { id } = useParams();
  const [avatarUrl, setAvatarUrl] = useState('/path-to-default-avatar.jpg');

  // Initial load to get user data
  useEffect(() => {
    loadAvatar();
    getUser(id); // Pass the user ID to the getUser action
  }, [getUser, id]);

    // Function to load avatar
    const loadAvatar = () => {
      if (auth.user) {
          if (auth.user.avatar && auth.user.avatar.startsWith('//www.gravatar.com/avatar/')) {
              // Use the Gravatar URL directly
              setAvatarUrl(`https:${auth.user.avatar}`);
          } else if (auth.user.avatarUrl) {
              // Use the presigned URL directly
              setAvatarUrl(auth.user.avatarUrl);
          } else {
              // Default avatar if no avatar URL is present
              setAvatarUrl('/path-to-default-avatar.jpg');
          }
      } else {
          // Default avatar if no user
          setAvatarUrl('/path-to-default-avatar.jpg');
      }
  };

  const formatLeaveValue = (value) => {
    const formatted = Math.max(value, 0);
    return Number.isInteger(formatted) ? formatted : formatted.toFixed(1);
};
  
  return (
    <Fragment>
      {user ? (
        <main className="roboto-regular main-top">
        <div className="usermng-pfp">
            <div className="usermng-user-particulars-container">
                <div className="usermng-avatar-cont">
                    <img src={avatarUrl}  alt="user-avatar" />
                </div>
                <div className="usermng-particular">
                    <div>
                        <div>{user.name}</div>
                        <div>{user.jobposition}</div>
                    </div>  
                    <div>
                        <div>Email: {user.email}</div>
                        <div>Contact No: {user.contact}</div>
                    </div>
                </div>
            </div>

           <div>
              <div className="leaveapp-header">Leave Entitlements:</div>  
              <div className="leaveapp-form">
                  <div>Annual : {formatLeaveValue(user.leave.annual)}</div>
                    <div>Medical/Sick Leave : {user.leave.medical}</div>
                    <div>Hospitalisation : {user.leave.hospitalisation}</div>
                    {user.gender === 'male' ? (
                        <div>Paternity: {user.leave.maternity}</div>
                    ) : (
                        <div>Maternity: {user.leave.maternity}</div>
                    )}
              </div>
            </div>

            <div className="button-wrapper roboto-bold">
                <Link to={`/leaveform/${user._id}`}  className="button">Apply for Leave</Link>
                <Link to={`/leave-records/${user._id}`}  className="button">Leave Records</Link>
            </div>
           </div>
    </main>        
      ) : (
        <Spinner />       
      )}
    </Fragment>
  );
};

UpdateLeaveForm.propTypes = {
  getUser: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({
      leave: PropTypes.shape({
        annual: PropTypes.number,
        medical: PropTypes.number,
        maternity: PropTypes.number
      })
    }),
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.users,
});

export default connect(mapStateToProps, { getUser })(UpdateLeaveForm);
