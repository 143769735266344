import { useState, useEffect } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import axios from 'axios';
import { debounce } from './debounce';
import setAuthToken from './setAuthToken';

export const useSortableGrid = () => {
  const [items, setItems] = useState([]);
  const [resizeActive, setResizeActive] = useState(false);
  const [resizeItemId, setResizeItemId] = useState(null);
  const [layoutLoading, setLayoutLoading] = useState(true); // State for layout loading

  // Fetch grid layout from MongoDB when the component mounts
  useEffect(() => {
    const fetchLayout = async () => {
      try {
        if (localStorage.token) {
          setAuthToken(localStorage.token);  // Attach token to request
        }
  
        setLayoutLoading(true);
        const response = await axios.get('/api/users/layout');
        console.log('Fetched layout from DB:', response.data); // Log fetched layout
  
        if (response.data && response.data.components) {
          setItems(response.data.components); // Update state with the fetched components
        }
      } catch (error) {
        console.error('Error fetching layout:', error);
      } finally {
        setLayoutLoading(false);
      }
    };
  
    fetchLayout();     
  }, []);             
  

  // Save layout to MongoDB whenever items change
  const saveLayoutToDB = async (layout) => {
    try {
      console.log('Saving layout:', layout);  // Log the layout to ensure it's correct
      if (localStorage.token) {
        setAuthToken(localStorage.token);  // Attach token to request
      }

      // Send the full layout data to the backend
      await axios.post('/api/users/layout', { components: layout });
      console.log('Grid layout saved to DB');
    } catch (error) {
      console.error('Error saving grid layout:', error);
    }
  };

  const debouncedSaveLayoutToDB = debounce(saveLayoutToDB, 1000); // 1-second debounce

  // Trigger debounced API call to save layout in MongoDB when items change
  useEffect(() => {
    if (items.length > 0) {
      debouncedSaveLayoutToDB(items);
    }
  }, [items]);

  const handleDragEnd = ({ active, over }) => {
    if (resizeActive) return;
    if (!over || active.id === over.id) return;

    const activeIndex = items.findIndex((item) => item.id === active.id);
    const overIndex = items.findIndex((item) => item.id === over.id);

    if (activeIndex !== overIndex) {
      // Update the positions of items after drag
      const updatedItems = arrayMove(items, activeIndex, overIndex);
      setItems(updatedItems);
    }
  };

  const handleResize = (id, newSize) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, size: newSize } : item
      )
    );
  };

  const handleActivateResize = (id) => {
    setResizeActive(true);
    setResizeItemId(id);
  };

  const exitResizeMode = () => {
    setResizeActive(false);
    setResizeItemId(null);
  };

  const addComponentToGrid = (component) => {
    const newComponent = {
      id: `${component}${Date.now()}`,
      size: 350,
      position: { x: 0, y: 0 },
      type: component,
    };
    setItems((prevItems) => [...prevItems, newComponent]);
  };

  const removeComponentFromGrid = async (id) => {
    // Remove the component from the state
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  
    try {
      // Save the updated layout to MongoDB
      if (localStorage.token) {
        setAuthToken(localStorage.token); // Attach token to request
      }
  
      // Send the updated components layout to the backend
      await axios.post('/api/users/layout', { components: updatedItems });
      console.log('Updated grid layout saved to DB');
    } catch (error) {
      console.error('Error saving updated grid layout:', error);
    }
  };
  

  return {
    items,
    resizeActive,
    resizeItemId,
    layoutLoading, // Expose layoutLoading
    handleDragEnd,
    handleResize,
    handleActivateResize,
    exitResizeMode,
    addComponentToGrid,
    removeComponentFromGrid,
  };
};
