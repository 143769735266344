import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPublicHolidays, submitLeaveCorrection } from '../../actions/leave';
import Spinner from '../layout/Spinner';

const CorrectionModal = ({ leave, fetchPublicHolidays, onClose, submitLeaveCorrection, loading, publicHolidays }) => {
  const [formData, setFormData] = useState({
    leaveId: leave._id,
    dateFrom: leave.dateFrom,
    dateTo: leave.dateTo,
    reason: leave.reason,
  });

  const [daysBetween, setDaysBetween] = useState('');
  const [halfDayOption, setHalfDayOption] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const [timeDifference, setTimeDifference] = useState({ hours: 0, minutes: 0 });

  useEffect(() => {
    fetchPublicHolidays();
  }, [fetchPublicHolidays]);

  useEffect(() => {
    const newDaysBetween = calculateDaysBetween(formData.dateFrom, formData.dateTo);
    setDaysBetween(newDaysBetween);

    // If the two dates are the same, show time inputs
    if (formData.dateFrom === formData.dateTo) {
      setHalfDayOption('');
    }

    // Update the time difference based on the time inputs
    setTimeDifference(calculateTimeDifference(timeFrom, timeTo));
  }, [formData.dateFrom, formData.dateTo, timeFrom, timeTo, publicHolidays]);

      // Timer effect after successful submission
      useEffect(() => {
        if (isSubmitted) {
          const timer = setTimeout(() => {
            handleClose(); 
          }, 3600);
    
          return () => clearTimeout(timer);
        }
      }, [isSubmitted]); // Only depend on `isSubmitted`

  const handleStartTimeChange = (e) => setTimeFrom(e.target.value);
  const handleEndTimeChange = (e) => setTimeTo(e.target.value);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleHalfDayChange = (e) => {
    setHalfDayOption(e.target.value);
    setDaysBetween('0.5');
  };

  const calculateDaysBetween = (fromDate, toDate) => {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);

    if (isNaN(startDate) || isNaN(endDate)) return '';

    if (startDate.toDateString() === endDate.toDateString()) {
      if (validateHalfDay(startDate)) {
        return '0.5';
      }
      return '';
    }

    let days = 0;
    while (startDate <= endDate) {
      if (!isWeekend(startDate) && !isPublicHoliday(startDate)) {
        days++;
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    return days > 0 ? days - 1 : '';
  };

  const isWeekend = (date) => {
    const day = new Date(date).getDay();
    return day === 6 || day === 0; // Saturday or Sunday
  };

  const isPublicHoliday = (date) => {
    return publicHolidays.some((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate.toDateString() === date.toDateString();
    });
  };

  const validateHalfDay = (date) => {
    return !(isWeekend(date) || isPublicHoliday(date));
  };

  const calculateTimeDifference = (timeFrom, timeTo) => {
    if (!timeFrom || !timeTo) {
      return { hours: 0, minutes: 0 };
    }

    const fromTime = new Date(`1970-01-01T${timeFrom}:00`);
    const toTime = new Date(`1970-01-01T${timeTo}:00`);

    if (toTime < fromTime) {
      toTime.setDate(toTime.getDate() + 1);
    }

    const diff = toTime - fromTime;

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const correctionData = {
      ...formData,
      daysBetween,
      ...(daysBetween === '0.5' && { halfDayOption }),
    };

    if (timeFrom && timeTo) {
      const [timeFromHours, timeFromMinutes] = timeFrom.split(':').map(Number);
      const [timeToHours, timeToMinutes] = timeTo.split(':').map(Number);

      correctionData.timeFromHours = timeFromHours;
      correctionData.timeFromMinutes = timeFromMinutes;
      correctionData.timeToHours = timeToHours;
      correctionData.timeToMinutes = timeToMinutes;
    }

    try {
      await submitLeaveCorrection(correctionData);
      setIsSubmitted(true);
    } catch (err) {
      setError('Failed to submit leave correction.');
      setIsSubmitted(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
    setIsSubmitted(false);
  };

  if (isSubmitting || loading) {
    return <Spinner />;
  }

  if (isSubmitted) {
    return (
      <div className="correction-modal-overlay">
        <div className="correction-modal-content submitted-modal-content">
          <img src="/images/register-check.gif" alt="submitted-animation" />
        </div>
      </div>
    );
  }

  const showTimeInput = formData.dateFrom === formData.dateTo;
  const showHalfDayOptions = daysBetween === '0.5' && !showTimeInput;

  // Display time difference if the dates are the same, otherwise show number of days
  const displayDaysOrTime = showTimeInput
    ? `${timeDifference.hours}h ${timeDifference.minutes}m`
    : daysBetween;

  return (
    <div className="correction-modal-overlay">
      <div className="correction-modal-content">
        <h3>Leave Correction Request</h3>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div>
            <label>From</label>
            <input
              type="date"
              name="dateFrom"
              value={formData.dateFrom.split('T')[0]}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>To</label>
            <input
              type="date"
              name="dateTo"
              value={formData.dateTo.split('T')[0]}
              onChange={handleChange}
            />
          </div>
          {showTimeInput ? (
            <div>
              <label>Time From:</label>
              <div className="cm-time">
                <input
                  className="time-input"
                  type="time"
                  name="timeFrom"
                  value={timeFrom}
                  onChange={handleStartTimeChange}
                />
                <span>Time To:</span>
                <input
                  className="time-input"
                  type="time"
                  name="timeTo"
                  value={timeTo}
                  onChange={handleEndTimeChange}
                />
              </div>
            </div>
          ) : showHalfDayOptions && (
            <div>
              <label>Select Half Day Option:</label>
              <div className="cm-hd">
                <input
                  className="correction-radio-input"
                  type="radio"
                  name="halfDayOption"
                  value="9am-1pm"
                  checked={halfDayOption === '9am-1pm'}
                  onChange={handleHalfDayChange}
                />
                <div>9am - 1pm</div>
              </div>
              <div className="cm-hd">
                <input
                  className="correction-radio-input"
                  type="radio"
                  name="halfDayOption"
                  value="2pm-6pm"
                  checked={halfDayOption === '2pm-6pm'}
                  onChange={handleHalfDayChange}
                />
                <div>2pm - 6pm</div>
              </div>
            </div>
          )}

          <div>
            <label>Reason</label>
            <textarea
              name="reason"
              value={formData.reason}
              onChange={handleChange}
            />
          </div>

          <div>
            <label>Number of Days / Time:</label>
            <input type="text" value={displayDaysOrTime} readOnly />
          </div>
          <button type="submit" className="correction-modal-btn">Submit Correction</button>
          <button type="button" onClick={handleClose} className="correction-modal-btn">Cancel</button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.leave.loading,
  publicHolidays: state.leave.publicHolidays,
});

export default connect(mapStateToProps, { fetchPublicHolidays, submitLeaveCorrection })(CorrectionModal);
